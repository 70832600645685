import { Application } from '@hotwired/stimulus'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// * Register here all the controllers you want to use without being lazy loaded.
// ! Those controllers files shouldn't be located in the `app/javascript/controllers` directory.

const directlyLoadedControllers = {}

Object.entries(directlyLoadedControllers).forEach(([name, Controller]) => {
  application.register(name, Controller)
})

export { application }

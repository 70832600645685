
// JS
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import { Turbo } from '@hotwired/turbo-rails'

// Disable Turbo Drive to keep as before
Turbo.session.drive = false
if (!window._rails_loaded) Rails.start()

ActiveStorage.start();

import "@/controllers"

// import all images with Vite
// import.meta.glob('../images/**/*')
import.meta.glob('@vendors/unify/img')

// <!-- JS Global Compulsory -->
import "../initializers/jquery"
import "jquery-migrate"
jQuery.migrateMute = true;
jQuery.migrateTrace = false;
import 'popper.js'
import 'bootstrap'

// // <!-- JS Implementing Plugins -->
import '@vendors/unify/vendor/appear.js'
import '@vendors/unify/vendor/dzsparallaxer/dzsparallaxer.js'
import '@vendors/unify/vendor/dzsparallaxer/dzsscroller/scroller.js'
import '@vendors/unify/vendor/dzsparallaxer/advancedscroller/plugin.js'
import '@vendors/unify/vendor/fancybox/jquery.fancybox'
import '@vendors/unify/vendor/cubeportfolio-full/cubeportfolio/js/jquery.cubeportfolio.min.js'
import '@vendors/unify/vendor/slick-carousel/slick/slick.js'

// <!-- JS Unify -->
import '@vendors/unify/dist/hs.core.js'
import '@vendors/unify/dist/components/hs.header.js'
import '@vendors/unify/dist/helpers/hs.hamburgers.js'
import '@vendors/unify/dist/components/hs.scroll-nav.js'
import '@vendors/unify/dist/components/hs.onscroll-animation.js'
import '@vendors/unify/dist/components/hs.tabs.js'
import '@vendors/unify/dist/components/hs.cubeportfolio.js'
import '@vendors/unify/dist/components/hs.popup.js'
import '@vendors/unify/dist/components/hs.carousel.js'
import '@vendors/unify/dist/components/hs.go-to.js'

// A recuperer
// import "@vendors/unify/dist/pages/page_contacts.js"

// // <!-- JS Customization -->
import '@vendors/unify/dist/custom.js'

// // <!-- JS Plugins Init. -->
$(document).on('ready', function () {


  // initialization of carousel
  $.HSCore.components.HSCarousel.init('.js-carousel')

  // initialization of header
  $.HSCore.components.HSHeader.init($('#js-header'))
  $.HSCore.helpers.HSHamburgers.init('.hamburger')

  // initialization of tabs
  $.HSCore.components.HSTabs.init('[role="tablist"]')

  // initialization of scroll animation
  $.HSCore.components.HSOnScrollAnimation.init('[data-animation]')

  // initialization of go to section
  $.HSCore.components.HSGoTo.init('.js-go-to')

  // initialization of popups
  $.HSCore.components.HSPopup.init('.js-fancybox-media', {
    helpers: {
      media: {},
      overlay: {
        css: {
          background: 'rgba(255, 255, 255, .8)'
        }
      }
    }
  })
})

$(window).on('load', function () {
  // initialization of HSScrollNav
  $.HSCore.components.HSScrollNav.init($('#js-scroll-nav'), {
    duration: 700
  })

  // initialization of cubeportfolio
  $.HSCore.components.HSCubeportfolio.init('.cbp')
})

$(window).on('resize', function () {
  setTimeout(function () {
    $.HSCore.components.HSTabs.init('[role="tablist"]')
  }, 200)
})
